<script>
import {mapGetters, mapMutations} from "vuex";
import {debounce} from "@/utils/debounce";
import pokerCommon from "@/pokerCommon";
import {GameStatus, RoomState} from "../../gameCommon";

export default {
  components: {
    GameTablePot: () => import(/* webpackChunkName: "game-table-pot-v2" */ '@/components/Game/TablePot.vue'),
    GameCardDealer: () => import(/* webpackChunkName: "game-card-dealer-v2" */ '@/components/Game/CardDealer.vue'),
    GameCommunityCards: () => import(/* webpackChunkName: "game-player-v2" */ '@/components/Game/CommunityCards.vue'),
    GamePlayerWrapper: () => import(/* webpackChunkName: "game-player-wrap-v2" */ '@/components/Game/PlayerWrap.vue'),
    GamePlayer: () => import(/* webpackChunkName: "game-player-v2" */ '@/components/Game/Player.vue'),
    WinnerHandRanking: () => import(/* webpackChunkName: "game-hand-ranking-v2" */ '@/components/Game/HandRanking.vue'),
    GameShowdown: () => import(/* webpackChunkName: "game-showdown-v2" */ '@/components/Game/Showdown.vue'),
    BuyinModal: () => import(/* webpackChunkName: "game-buyin-modal-v2" */ '@/components/Game/Modal/Buyin.vue'),
    GameCountDown: () => import(/* webpackChunkName: "game-count-down-v2" */ '@/components/Game/CountDown.vue'),
    GameWinnerResult: () => import(/* webpackChunkName: "game-winner-result-v2" */ '@/components/Game/WinnerResult.vue'),
  },
  data() {
    return {
      isShowShowdown: false,
      selectedPosition: 0,
    }
  },
  computed: {
    ...mapGetters('game', [
      'heartbeat',
      'roomInfo',
      'gameInfo',
      'playersInfo',
      'myInfo',
      'currentRoomState',
      'isShowResult',
      'tableHandRanking',
      'isTournamentGame',
      'tournamentInfo'
    ]),
    ...mapGetters('user', ['isShownBuyinPopup']),
    ...mapGetters('config', ['tableResourceNumber']),
    handRanking: {
      get() {
        return this.tableHandRanking;
      },
      set(value) {
        this.$store.commit('game/SET_TABLE_HAND_RANKING', value);
      }
    },
    tableImage() {
      return require(`@/assets/v2/img/table${this.tableResourceNumber}.webp`)
    },
    tableDesktopImage() {
      return require(`@/assets/v2/img/desktop/table${this.tableResourceNumber}.webp`)
    },
    roundKey: {
      get() {
        return this.$store.getters['game/roundKey'];
      },
      set(value) {
        this.$store.commit('game/UPDATE_ROUND_ID', value);
      }
    },
    isSeated() {
      const index = this.myInfo && (this.myInfo.idx || -1);
      return index !== -1;
    },
    playerLimit() {
      return this.gameInfo.playerCnt || 0;
    },
    buyinModalState: {
      get() {
        return this.isShownBuyinPopup;
      },
      set(state) {
        this.$store.commit('user/SET_BUYIN_MODAL_VISIBILITY', state);
      }
    },
    blindUpTimestamp(){
      return this.roomInfo.tRoundStart + (this.roomInfo.blindUpTime * 1000) || 0;
    },
    isShowBreakTime(){
      return this.roomInfo.state === RoomState.BreakTime && this.roomInfo.gameStatus !== GameStatus.End;
    }
  },
  watch: {
    roundKey: {
      immediate: true,
      handler: 'resetGameData'
    },
    currentRoomState(state, _state) {
      this.nextRound(state, _state);
    },
  },
  methods: {
    ...mapMutations('game', {
      __setPlayerLastAction: 'SET_LAST_ACTION',
    }),
    async resetGameData() {
      await this.$store.dispatch('game/reset');
    },
    onSound(body) {
      pokerCommon.playSound(body.name);
    },
    onLastAction(lastActionInfo) {
      this.__setPlayerLastAction(lastActionInfo);
    },
    onShowdownStart() {
      this.isShowShowdown = true;

      setTimeout(() => {
        this.isShowShowdown = false;
      }, 2000)
    },
    onPlayerWin(handName) {
      this.handRanking = handName;
    },
    updateBestHand: debounce(function () {
      const bestHandPlayer = this.playersInfo.filter(player => player.isBestHand);

      if (!bestHandPlayer) {
        return;
      }

      this.$store.commit('game/SET_BEST_HAND_DETAILS', bestHandPlayer.handDetails);
    }, {leading: true}, 50),
    onCardHighlight() {
      const bestHandPlayer = this.playersInfo.find(player => player.isBestHand);

      if (!bestHandPlayer) {
        return;
      }

      this.$store.commit('game/SET_BEST_HAND_DETAILS', bestHandPlayer.handDetails);
    },
    nextRound(state, _state) {
      const isNewRound = state === this.roomState.Deal && _state === this.roomState.ShowResult;
      isNewRound && this.roundKey++;
    },
    onSeatRequest({position}) {
      if (!this.gameInfo) {
        return;
      }

      this.selectedPosition = position;
      this.buyinModalState = true;
    },
  },
  created() {
    this.EventBus.$on(`poker-sound`, this.onSound);
    this.EventBus.$on('PokerRemainSec', sec => {
      this.$store.commit('game/SET_TURN_REMAINS', sec);
    });
    this.EventBus.$on('PokerRemainSecMs', sec => {
      this.$store.commit('game/SET_TURN_REMAINS_MS', sec)
    });
    this.EventBus.$on('PokerLastAction', this.onLastAction);
    this.EventBus.$on('onShowdownStart', this.onShowdownStart);
  },
  beforeDestroy() {
    this.EventBus.$off(`poker-sound`);
    this.EventBus.$off('PokerLastAction');
    this.EventBus.$off('onShowdownStart');
  }
}
</script>

<template>
  <div class="wrap_table">
    <picture>
      <source :srcset="tableDesktopImage" media="(min-width:768px)" class="table" />
      <img :src="tableImage" alt="" class="table">
    </picture>
    <!-- 카드 딜러 : 애니메이션 -->
    <GameCardDealer/>
    <!-- POT -->
    <GameTablePot/>
    <!-- 승리 족보 -->
    <WinnerHandRanking v-if="isShowResult && handRanking" hand-type="table" :hand-ranking="handRanking"/>
    <!-- 커뮤니티 카드 -->
    <GameCommunityCards/>
    <!-- 플레이어 자리 -->
    <div :class="{
      room_player06: playerLimit === 6,
      room_player09: playerLimit === 9,
    }">
      <template v-for="position in playerLimit">
        <GamePlayerWrapper
            :key="`player-${position}__${roundKey}`"
            :position="position"
            @buyin="onSeatRequest"
            @cancel="showBuyinPopup = false"
        >
          <template v-slot:default="{empty, player, me}">
            <span v-if="empty">SIT</span>
            <GamePlayer
                v-else
                :player="player"
                :me="me"
                @win="onPlayerWin"
                @card-highlight="onCardHighlight"
            />
          </template>
        </GamePlayerWrapper>
      </template>
    </div>

    <GameWinnerResult v-show="isTournamentGame" />

    <!-- 쇼다운 -->
    <GameShowdown v-if="isShowShowdown"/>

    <!-- 브레이크 타임 -->
    <div class="area_brake_time" v-show="isShowBreakTime">
      <div class="ico_time"><img src="@/assets/v2/svg/ico_time.svg" alt=""></div>
      <div class="num_time">
        <GameCountDown v-if="isShowBreakTime" :remainTimestamp="blindUpTimestamp" :formatType="2" />
      </div>
      <div class="txt_time">브레이크타임</div>
    </div>

    <!-- 게임 참여 -->
    <BuyinModal v-model="buyinModalState" :selected-position="selectedPosition"/>
  </div>
</template>

<style scoped>

</style>