var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap_table"},[_c('picture',[_c('source',{staticClass:"table",attrs:{"srcset":_vm.tableDesktopImage,"media":"(min-width:768px)"}}),_c('img',{staticClass:"table",attrs:{"src":_vm.tableImage,"alt":""}})]),_c('GameCardDealer'),_c('GameTablePot'),(_vm.isShowResult && _vm.handRanking)?_c('WinnerHandRanking',{attrs:{"hand-type":"table","hand-ranking":_vm.handRanking}}):_vm._e(),_c('GameCommunityCards'),_c('div',{class:{
    room_player06: _vm.playerLimit === 6,
    room_player09: _vm.playerLimit === 9,
  }},[_vm._l((_vm.playerLimit),function(position){return [_c('GamePlayerWrapper',{key:("player-" + position + "__" + _vm.roundKey),attrs:{"position":position},on:{"buyin":_vm.onSeatRequest,"cancel":function($event){_vm.showBuyinPopup = false}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var empty = ref.empty;
  var player = ref.player;
  var me = ref.me;
return [(empty)?_c('span',[_vm._v("SIT")]):_c('GamePlayer',{attrs:{"player":player,"me":me},on:{"win":_vm.onPlayerWin,"card-highlight":_vm.onCardHighlight}})]}}],null,true)})]})],2),_c('GameWinnerResult',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTournamentGame),expression:"isTournamentGame"}]}),(_vm.isShowShowdown)?_c('GameShowdown'):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowBreakTime),expression:"isShowBreakTime"}],staticClass:"area_brake_time"},[_vm._m(0),_c('div',{staticClass:"num_time"},[(_vm.isShowBreakTime)?_c('GameCountDown',{attrs:{"remainTimestamp":_vm.blindUpTimestamp,"formatType":2}}):_vm._e()],1),_c('div',{staticClass:"txt_time"},[_vm._v("브레이크타임")])]),_c('BuyinModal',{attrs:{"selected-position":_vm.selectedPosition},model:{value:(_vm.buyinModalState),callback:function ($$v) {_vm.buyinModalState=$$v},expression:"buyinModalState"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ico_time"},[_c('img',{attrs:{"src":require("@/assets/v2/svg/ico_time.svg"),"alt":""}})])}]

export { render, staticRenderFns }